<template>
    <div
        class="
            max-w-2xl
            mx-auto
            px-4
            sm:px-6
            lg:max-w-7xl lg:px-8
            pt-16
            pb-20
            relative
        "
    >
        <div
            class="
                grid grid-cols-1
                gap-y-10 gap-x-6
                sm:grid-cols-2
                lg:grid-cols-4
                xl:gap-x-10
            "
        >
            <PupCardVue
                v-for="(pup, index) in pups"
                class="transform md:odd:rotate-2 md:even:-rotate-1"
                :key="index"
                :imageSrc="pup.imageSrc"
            />
        </div>
        <floating-contact-button-vue />
    </div>
</template>

<script>
    import FloatingContactButtonVue from './FloatingContactButton.vue';
    import PupCardVue from './PupCard.vue';
    const pups = new Array(30).fill(undefined).map((_, i) => ({
        name: `${i}`,
        imageSrc: require(`@/assets/pup${i + 1}.jpg`),
    }));

    export default {
        name: 'PuppiesList',
        components: {
            PupCardVue,
            FloatingContactButtonVue,
        },
        setup: () => {
            return {
                pups,
            };
        },
    };
</script>
