
    import PuppiesListVue from '@/components/PuppiesList.vue';
    import { defineComponent } from 'vue';
    import ScrollBehavior from '@/behaviors/Scroll';

    export default defineComponent({
        components: {
            PuppiesListVue,
        },
        mounted() {
            ScrollBehavior.toTop();
        },
    });
