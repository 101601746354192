<template>
    <div class="bg">
        <puppies-list-vue />
    </div>
</template>
<script lang="ts">
    import PuppiesListVue from '@/components/PuppiesList.vue';
    import { defineComponent } from 'vue';
    import ScrollBehavior from '@/behaviors/Scroll';

    export default defineComponent({
        components: {
            PuppiesListVue,
        },
        mounted() {
            ScrollBehavior.toTop();
        },
    });
</script>

<style lang="scss" scoped>
    .bg {
        background-image: url('../assets/pattern.svg');
        background-repeat: repeat;
    }
</style>
