<template>
    <div
        class="
            w-full
            min-h-80
            bg-gray-200
            aspect-w-1 aspect-h-1
            rounded-md
            overflow-hidden
            transition-all
            duration-300
            lg:h-80 lg:aspect-none
            shadow-md
        "
    >
        <img
            :src="imageSrc"
            class="w-full h-full object-center object-cover lg:w-full lg:h-full"
        />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        data: () => ({
            over: false,
        }),
        props: {
            imageSrc: {
                type: String,
                required: true,
            },
        },
    });
</script>
