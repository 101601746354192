
    import { defineComponent } from 'vue';

    export default defineComponent({
        data: () => ({
            over: false,
        }),
        props: {
            imageSrc: {
                type: String,
                required: true,
            },
        },
    });
