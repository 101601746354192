<template>
    <div
        v-if="visible"
        class="
            fixed
            rounded-md
            shadow
            bottom-12
            right-4
            sm:right-12
            animate-bounce
        "
    >
        <a
            href="#"
            class="
                w-full
                flex
                items-center
                justify-center
                px-8
                py-3
                border border-transparent
                text-base
                font-medium
                rounded-md
                text-white
                bg-red-600
                hover:bg-red-700
                md:py-4 md:text-lg md:px-10
            "
            @click.prevent="$router.push({ name: 'Contact' })"
        >
            Contactez-nous
        </a>
    </div>
</template>

<script>
    export default {
        name: 'FloatingContactButton',
        data: () => ({
            visible: false,
            listener: null,
        }),
        created() {
            (this.listener = () => {
                this.visible =
                    window.top.scrollY > 200 &&
                    window.top.scrollY < document.body.clientHeight - 1000;
            }),
                window.addEventListener('scroll', this.listener);
        },
        beforeUnmount() {
            this.visible = false;
            window.removeEventListener('scroll', this.listener);
        },
    };
</script>
